<template>
  <div class="relative">
    <canvas ref="canvasConnect" :style="{ height: '150px', width: '150px' }" />
  </div>
</template>

<script>
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default {
  data() {
    return {
      project: null,
      ballPositions: [],
      handle_len_rate: 0.4,
      circlePaths: [],
      largeCircle: null,
      connections: null,
    };
  },
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    paper.setup(this.$refs.canvasConnect);
    this.project = paper.project;
    this.initMetaball();
  },
  unmounted() {
    if (this.project) {
      this.project.clear();
    }
    this.circlePaths = [];
  },
  methods: {
    initMetaball() {
      for (let i = 0, l = 2; i < l; i++) {
        const circlePath = new paper.Path.Circle({
          center: [60 + i * 60, 60],
          radius: 20,
        });
        circlePath.strokeColor = "#000000";
        this.circlePaths.push(circlePath);
      }
      for (let i = 0, l = 2; i < l; i++) {
        const circlePath = new paper.Path.Circle({
          center: [120 - i * 60, 120],
          radius: 20,
        });
        circlePath.strokeColor = "#000000";
        this.circlePaths.push(circlePath);
      }

      for (let i = 0, l = this.circlePaths.length - 1; i < l; i++) {
        let radius = 0;

        let circle2 = new paper.Path.Circle({
          center: [
            this.circlePaths[i]?.position.x,
            this.circlePaths[i]?.position.y,
          ],
          radius,
          fillColor: "black",
        });

        setTimeout(() => {
          gsap?.to(circle2, {
            radius: 20,
            duration: 0.8,
            delay: i * 0.2,
            onUpdate: () => {
              if (radius > 20 && !this.circlePaths[i]) {
                return;
              }
              radius++;
              this.project.activate();
              circle2.remove();
              circle2 = new paper.Path.Circle({
                center: [
                  this.circlePaths[i]?.position.x,
                  this.circlePaths[i]?.position.y,
                ],
                radius,
                fillColor: "black",
              });
            },
            scrollTrigger: {
              trigger: this.$refs.canvasConnect,
              start: "top bottom",
            },
          });
        }, 200);
      }
    },

    hover() {
      if (this.project) {
        this.project.clear();
      }
      this.circlePaths = [];
      this.project.activate();
      this.initMetaball();
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  /*height: 700px;*/
  display: block;
  background-color: transparent;
  left: -20px;
  position: relative;

  @media screen and (max-width: 1024px) {
    cursor: auto;
  }
}
</style>
