<template>
  <div class="relative">
    <canvas ref="canvasInfinite" :style="{ height: '150px', width: '150px' }" />
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  data() {
    return {
      project: null,
      ballPositions: [],
      handle_len_rate: 0.4,
      circlePaths: [],
      largeCircle: null,
      connections: null,
      firstTimeDelay: 2.6,
    };
  },
  mounted() {
    paper.setup(this.$refs.canvasInfinite);
    this.project = paper.project;
    this.initMetaball();
  },
  unmounted() {
    if (this.project) {
      this.project.clear();
    }
    this.circlePaths = [];
  },
  methods: {
    initMetaball() {
      this.project.view.hitTest = (event) => {};
      this.project.currentStyle = {
        fillColor: "black",
      };

      for (let i = 0, l = 3; i < l; i++) {
        const circlePath = new paper.Path.Circle({
          center: [120, 120],
          radius: 20,
        });
        circlePath.name = "name" + i;
        circlePath.onClick = () => {};
        circlePath.strokeColor = "#000000";
        this.circlePaths.push(circlePath);
      }

      const circlePath3 = new paper.Path.Circle({
        center: [60, 120],
        radius: 20,
      });
      circlePath3.fillColor = null;
      circlePath3.strokeColor = "black";

      setTimeout(() => {
        gsap?.to(this.circlePaths[1]?.position, {
          duration: 1,
          x: 60,
          y: 60,
          delay: 0.4 + this.firstTimeDelay,
          ease: "elastic.out(1, 0.3)",
          scrollTrigger: {
            trigger: this.$refs.canvasInfinite,
            start: "top bottom",
          },
          onStart: () => {
            const refresh = (event) => {
              this.generateConnections(this.circlePaths);
            };
            gsap.ticker.fps(30);
            gsap.ticker.add(refresh);
            this.project.activate();
          },
        });
        gsap.to(this.circlePaths[2]?.position, {
          duration: 1,
          delay: 1 + this.firstTimeDelay,
          x: 120,
          y: 60,
          ease: "elastic.out(1, 0.3)",
          scrollTrigger: {
            trigger: this.$refs.canvasInfinite,
            start: "top bottom",
          },
        });
      }, 150);

      let radius = 0;

      let circle = new paper.Path.Circle({
        center: [circlePath3.position.x, circlePath3.position.y],
        radius,
        fillColor: "black",
      });

      setTimeout(() => {
        gsap.to(circle, {
          duration: 1,
          delay: 0.6 + this.firstTimeDelay,
          ease: "elastic.out(1, 0.3)",
          onUpdate: () => {
            if (radius > 20) {
              return;
            }
            radius++;
            this.project.activate();
            circle.remove();
            circle = new paper.Path.Circle({
              center: [circlePath3?.position.x, circlePath3?.position.y],
              radius,
              fillColor: "black",
            });
          },
          scrollTrigger: {
            trigger: this.$refs.canvasInfinite,
            start: "top bottom",
          },
        });

        this.firstTimeDelay = 0;
      }, 500);

      this.connections = new paper.Group();
      this.generateConnections(this.circlePaths);
    },

    generateConnections(paths) {
      this.connections.removeChildren();
      for (let i = 0, l = paths.length; i < l; i++) {
        for (let j = i - 1; j >= 0; j--) {
          const maxD = 90;
          // if (i <= this.currentSelected) {
          //     maxD = 31
          // }
          const path = this.metaball(paths[i], paths[j], 0.6, 2.0, maxD, j);
          if (path) {
            this.connections.appendTop(path);
            // path.removeOnMove();
          }
        }
      }
    },
    metaball(ball1, ball2, v, handle_len_rate, maxDistance, j) {
      const center1 = ball1?.position;
      const center2 = ball2?.position;
      let radius1 = ball1.bounds.width / 2;
      let radius2 = ball2.bounds.width / 2;
      const pi2 = Math.PI / 2;
      const d = center1.getDistance(center2);
      let u1, u2;

      if (ball1.name === "name2" && ball2.name === "name1") return;
      if (radius1 === 0 || radius2 === 0) return;

      if (d > maxDistance || d <= Math.abs(radius1 - radius2)) {
        return;
      } else if (d < radius1 + radius2) {
        u1 = Math.acos(
          (radius1 * radius1 + d * d - radius2 * radius2) / (2 * radius1 * d),
        );
        u2 = Math.acos(
          (radius2 * radius2 + d * d - radius1 * radius1) / (2 * radius2 * d),
        );
      } else {
        u1 = 0;
        u2 = 0;
      }

      ball2.fillColor = "#000000";
      const angle1 = center2.subtract(center1).getAngleInRadians();
      const angle2 = Math.acos((radius1 - radius2) / d);
      const angle1a = angle1 + u1 + (angle2 - u1) * v;
      const angle1b = angle1 - u1 - (angle2 - u1) * v;
      const angle2a = angle1 + Math.PI - u2 - (Math.PI - u2 - angle2) * v;
      const angle2b = angle1 - Math.PI + u2 + (Math.PI - u2 - angle2) * v;
      const p1a = center1.add(this.getVector(angle1a, radius1));
      const p1b = center1.add(this.getVector(angle1b, radius1));
      const p2a = center2.add(this.getVector(angle2a, radius2));
      const p2b = center2.add(this.getVector(angle2b, radius2));

      const totalRadius = radius1 + radius2;
      let d2 = Math.min(
        v * handle_len_rate,
        p1a.subtract(p2a).length / totalRadius,
      );

      d2 *= Math.min(1, (d * 2) / (radius1 + radius2));

      radius1 *= d2;
      radius2 *= d2;

      const path = new paper.Path({
        segments: [p1a, p2a, p2b, p1b],
        style: ball2.style,
        closed: false,
      });
      const segments = path.segments;

      segments[0].handleOut = this.getVector(angle1a - pi2, radius1);
      segments[1].handleIn = this.getVector(angle2a + pi2, radius2);
      segments[2].handleOut = this.getVector(angle2b - pi2, radius2);
      segments[3].handleIn = this.getVector(angle1b + pi2, radius1);
      return path;
    },
    getVector(radians, length) {
      return new paper.Point({
        angle: (radians * 180) / Math.PI,
        length,
      });
    },
    hover() {
      if (this.project) {
        this.project.clear();
      }
      this.circlePaths = [];
      this.project.activate();

      const refresh = (event) => {
        this.generateConnections(this.circlePaths);
      };
      gsap.ticker.add(refresh);

      const myTimeout = setTimeout(() => {
        gsap.ticker.remove(refresh);
      }, 3000);
      this.initMetaball();
    },
  },
};
</script>

<style scoped>
canvas {
  width: 100%;
  display: block;
  background-color: transparent;
  left: -20px;
  position: relative;
  @media screen and (max-width: 1024px) {
    cursor: auto;
  }
}
</style>
